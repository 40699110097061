import React, { useState } from 'react';
import './Login.scss';
import LoginCard from '../../components/LoginForm/LoginCard';
import Card from '@material-ui/core/Card';
import webtitleImg from '../../assets/images/brand/title.png';
import { IonImg } from '@ionic/react';
import Footer from '../../components/Footer/Footer';
const Login: React.FC = () => {
  const [isEntryLoader, setIsEntryLoader] = useState<boolean>(true);

  setInterval(() => {
    setIsEntryLoader(false);
  }, 3000);
  return (
    <div id="bg">
      <div className="center-content">
        {isEntryLoader ? (
          <>
            <div className="logo_loading">
              {/* <IonImg src={webtitleImg} className="logo" /> */}
              {/* <div className="logo-text">SilverExch</div> */}
              <i className="fa fa-spinner fa-spin fa-fw" aria-hidden="true"></i>
            </div>
          </>
        ) : (
          <>
            <IonImg src={webtitleImg} className="logo" />
            {/* <div className="logo-text">SilverExch</div> */}
            <Card className="card">
              <LoginCard />
            </Card>
  
          </>
        )}
        
      </div>
      <div className='footer-login'>
            <div className='footer-top'>
          <div className='footer-links'>
            <nav className='navbar-nav'>
              <div className='terms-condi-ctn'>
                <a href="/terms-conditions" className="nav-link">Terms and Conditions</a>

                <a href="/responsible-gambling" className="nav-link" target="_blank"> Responsible Gaming</a>
              </div>
              {/* <ul className='terms-condi-ctn'>
                <li className='nav-item'><a href="#terms" className="nav-link">Terms and Conditions</a></li>

                <li className='nav-item'><a href="#gaming" className="nav-link" target="_blank"> Responsible Gaming</a></li>
              </ul> */}
            </nav>
          </div>
          <div className='support-detail'> <h2>24X7 Support</h2></div>
        </div>
      </div>
    </div>
  );
};

export default Login;
